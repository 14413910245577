<template>
  <div class="main">
    <div class="custom-tab">
      <div class="custom-tab-title" @click="changeFlag('member')"><span>{{ $t('member.detail') }}</span><i
          :class="flag == 'member' ? 'active' : ''"></i></div>
      <div class="custom-tab-title" @click="changeFlag('history')"><span>{{ $t('member.history') }}</span><i
          :class="flag == 'history' ? 'active' : ''"></i></div>
      <div class="custom-tab-title" @click="changeFlag('api')"><span>{{ $t('member.api') }}</span><i
          :class="flag == 'api' ? 'active' : ''"></i></div>
    </div>
    <div class="custom-tab-body">
      <transition name="fade">
        <div v-show="flag == 'member'" class="custom-tab-item">
          <div class="custom-tab-body-title">{{ $t('member.information') }}</div>
          <div class="custom-tab-body-item">
            <div class="content">
              <span class="label">{{ $t('member.userInfo') }}</span>
              <span class="value">{{ userInfo.username }}</span>
            </div>
          </div>
          <div class="custom-tab-body-item">
            <div class="content">
              <span class="label">{{ $t('member.password') }}</span>
              <span class="value">{{ '******' }}</span>
            </div>
            <el-button size="mini" class="button" @click="edit('password')">{{ $t('member.edit') }}</el-button>
          </div>
          <div class="custom-tab-body-item">
            <div class="content">
              <span class="label">{{ $t('member.email') }}</span>
              <span class="value">{{ userInfo.email }}</span>
            </div>
            <el-button size="mini" class="button" @click="edit('email')">{{ $t('member.edit') }}</el-button>
            <!-- <el-button v-show="!countdown" size="mini" class="button" @click="edit('email')">{{ $t('member.edit') }}</el-button> -->
          </div>
          <div class="custom-tab-body-item">
            <div class="content">
              <span class="label">{{ $t('member.organization') }}</span>
              <span class="value">{{ organizationType[userInfo.organization_type] }}</span>
            </div>
            <el-button size="mini" class="button" @click="edit('organization_type')">{{ $t('member.edit') }}</el-button>
          </div>
          <div class="custom-tab-body-item">
            <div class="content">
              <span class="label">{{ $t('member.usage') }}</span>
              <span class="value">{{ purpose[userInfo.purpose] }}</span>
            </div>
            <el-button size="mini" class="button" @click="edit('purpose')">{{ $t('member.edit') }}</el-button>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-show="flag == 'history'" class="custom-tab-item">
          <div class="download" style="min-height: 500px;width: auto;margin-top:0">
            <div class="custom-tab-body-title" style="margin-top: 0;">{{ $t('member.timeFrame') }}:</div>
            <div class="selecttime">
              <el-date-picker v-model="startTime" type="datetime" format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" :placeholder="$t('member.selectTime')">
              </el-date-picker>
              <span class="jianju">~</span>
              <el-date-picker v-model="endTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
                :placeholder="$t('member.selectTime')">
              </el-date-picker>
              <!--              <span class="select" @click="historyList">查询</span>-->
              <el-button @click="historyList" type="primary" plain
                style="margin-left: 30px">{{ $t('member.search') }}</el-button>

            </div>
            <div class="downListFlex" v-for="(item, index) in downloadList" :key="index">
              <div class="downloadListFlex">
                <span class="downIndex">{{ index + 1 }}</span>
                <div>
                  <div class="downTitle">
                    {{ $t("member.randomCreateTime") }}：{{ item && item.random && item.random.add_bj_time |
                      parseTime("{y}-{m}-{d} {h}:{i}") }}
                  </div>
                  <div class="downTime">{{ $t("member.downLoadTime") }}：{{ item.addtime | parseTime }}</div>
                </div>
              </div>
              <div class="">
                <!--                <div @click="downloadTxt(item.random.original, 'fileName')">下载</div>-->
                <!--                <div @click="goDetails(item.random.pulse_index,index)">查看详情</div>-->
                <el-button @click="downloadTxt(item.random, item.sign_name + '_Beacon_', item.external_id)" type="primary"
                  plain>{{ $t('member.downLoad') }}</el-button>
                <el-button @click="goDetails(item.random.pulse_index, index, item.external_id)" type="primary"
                  plain>{{ $t('member.randomDetail') }}</el-button>
              </div>
            </div>
            <div class="paging">
              <el-pagination class="text_center" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="pagesize" layout="total,  prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </div>
          </div>

        </div>
      </transition>
      <transition name="fade">
        <div v-show="flag == 'api'" class="custom-tab-item">
          <apiDocument />
        </div>
      </transition>

    </div>
    <el-dialog :title="$t('member.edit')" :visible.sync="dialogVisible" width="40%">
      <el-form :model="userInfo" label-width="80px">
        <el-form-item v-if="editType == 'password'" :label="$t('member.password')" label-width="125px">
          <el-input v-model="userInfo.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="editType == 'password'" :label="$t('member.newPassword')" label-width="125px">
          <el-input v-model="userInfo.newPassword" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="editType == 'password'" :label="$t('member.confirmPassword')" label-width="125px">
          <el-input v-model="userInfo.confirmPassword" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="editType == 'email'" :label="$t('member.email')">
          <el-input v-model="userInfo.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="editType == 'email'" :label="$t('member.Verifica')">
          <el-input v-model="userInfo.code">
            <el-button v-show="countdown" @click="sendEmail()" slot="append">{{ $t('member.SVerifica') }}</el-button>
            <el-button v-show="!countdown" slot="append">{{ count }} s</el-button>
          </el-input>
        </el-form-item>

        <el-form-item v-if="editType == 'organization_type'" :label="$t('member.organization')">
          <el-select v-model="userInfo.organization_type" :placeholder="$t('member.organization')">
            <el-option v-for="(item, index) in organizationType" :key="index" :label="item" :value="index"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="editType == 'purpose'" :label="$t('member.usage')">
          <el-select v-model="userInfo.purpose" :placeholder="$t('member.usage')">
            <el-option v-for="(item, index) in purpose" :key="index" :label="item" :value="index"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('member.cancel') }}</el-button>
        <el-button type="primary" @click="editDo">{{ $t('member.confirm') }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import apiDocument from '../components/apiDocument.vue';
export default {
  components: {
    apiDocument
  },
  data() {
    return {
      countdown: true,
      count: '',
      timer: null,
      editType: '',
      dialogVisible: false,
      // 所属的机构类型
      organizationType: ['科研院所', '商业企业', '政府机构', '个人'],
      // 用途
      purpose: ['科学研究', '商业应用', '个人应用', '其他'],
      flag: 'member',
      userInfo: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      startTime: "",
      endTime: "",
      downloadList: [],
      currentPage: 1, //初始页
      pagesize: 5,
      total: 0,
      randomId: "",
      yuyan: localStorage.getItem('locale'),
    };
  },
  filters: {
    //时间戳转日期
    parseTime(time, cFormat) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            // support "1548221490638"
            time = parseInt(time);
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.historyList();
    if (!this.yuyan) {
      this.yuyan = 'zh'
    }
  },
  created() {
    let userToken = this.$cookie.get("userToken");
    if (!userToken) this.$router.push("/login");
    this.$post("/index/member/detail", {}).then((res) => {
      this.userInfo = res.data
    });
  },
  methods: {
    sendEmail1() {
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.countdown = false;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
        } else {
          this.countdown = true;
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000)
    },
    sendEmail() {
      this.yuyan = localStorage.getItem('locale')
      this.$post("/index/login/sendEmail", { email: this.userInfo['email'], sendType: 'editEmailCode' }).then((res) => {
        if (res.code === 0) {
          // this.$message.success(res.msg ? res.msg : '操作成功');
          if (this.yuyan == "zh") {
            this.$message({
              message: '已发送验证码至您的邮箱',
              type: 'success',
              offset: '350'
            });

          } else {
            this.$message({
              message: 'A verification code has been sent to your mailbox.',
              type: 'success',
              offset: '350'
            });

          }
          this.sendEmail1() // 调用接口获取验证码
        } else {
          // this.$message.error(res.msg ? res.msg : '操作异常');
          this.$message({
            message: res.msg ? res.msg : '操作异常',
            type: 'error',
            offset: '350'
          });


        }
      });
    },
    editDo() {
      this.yuyan = localStorage.getItem('locale')
      if (this.userInfo.confirmPassword != this.userInfo.newPassword) {
        // this.$message.error('两次密码不一致')
        if (this.yuyan == "zh") {
          this.$message({
            message: '两次密码不一致',
            type: 'error',
            offset: '350'
          });
        } else {
          this.$message({
            message: 'Two passwords are inconsistent.',
            type: 'error',
            offset: '350'
          });
        }

        return
      }
      if (!this.userInfo.confirmPassword && !this.userInfo.newPassword) {
        if (this.yuyan == "zh") {
          this.$message({
            message: '密码不可为空',
            type: 'error',
            offset: '350'
          });
        } else {
          this.$message({
            message: 'Password cannot be blank.',
            type: 'error',
            offset: '350'
          });
        }
        return
      }
      let res = this.userInfo[this.editType]
      if (!res && res !== 0) {
        if (this.yuyan == "zh") {
          return this.$message({
            message: '内容不可为空',
            type: 'error',
            offset: '350'
          });
        } else {
          return this.$message({
            message: 'Content cannot be empty.',
            type: 'error',
            offset: '350'
          });
        }

      }
      let data = { type: this.editType, data: res };
      if (this.editType == 'email') {
        if (!this.userInfo.code) {
          if (this.yuyan == "zh") {
            this.$message({
              message: '验证码不可为空',
              type: 'error',
              offset: '350'
            });
          } else {
            this.$message({
              message: 'Verification code cannot be empty',
              type: 'error',
              offset: '350'
            });
          }
        }
        data['code'] = this.userInfo.code
      }
      if (data.type == 'password') {
        data.data = this.userInfo.newPassword
        data.original_password = this.userInfo.password
      }
      this.$post("/index/member/edit", data).then((res) => {
        if (res.code === 0) {
          // this.$message.success('操作成功');
          if (this.yuyan == "zh") {
            this.$message({
              message: '操作成功',
              type: 'success',
              offset: '350'
            });
          } else {
            this.$message({
              message: 'Successful operation',
              type: 'success',
              offset: '350'
            });
          }
          this.dialogVisible = false
          this.userInfo.code = '';
        } else {
          // this.$message.error(res.msg ? res.msg : '操作异常')
          this.$message({
            message: res.msg ? res.msg : '操作异常',
            type: 'error',
            offset: '350'
          });
        }
      });
    },
    edit(type) {
      this.editType = type;
      this.dialogVisible = true
    },
    changeFlag(flag) {
      console.log(111, flag);
      this.flag = flag
    },
    //查看详情
    goDetails(pulse_index, index, external_id = 0) {
      // this.$router.push("/acquire?pulse_index="+pulse_index+'&type=type');
      this.$router.push({ path: '/acquire', query: { 'index': '2', 'pulse_index': pulse_index, 'type': 'type', 'external_id': external_id } })
      // this.$router.push("/acquire"+`index=${2}+pulse_index=${pulse_index}`)
    },
    //下载随机数
    downloadTxt(data, fileName, external_id) {
      this.yuyan = localStorage.getItem('locale')
      data = Object.assign({}, data);
      console.log(data, fileName, external_id);
      this.$post("/index/setting/index").then((res) => {
        if (res.data.download == 1) {
          delete data.add_bj_time;
          let pqc = Object.assign({}, data);
          delete data.output_value_pqc;
          delete data.signature_pqc;
          delete pqc.signature_value;
          delete pqc.output_value;
          const file = new Blob(["RSA：\n" + JSON.stringify(data) + "\n\n\n\nPQC：\n" + JSON.stringify(pqc)], { type: "text/plain" });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(file);
          a.download = fileName;
          a.click();
          let parameter = {
            random_id: data.id,
          };
          parameter.external_id = external_id;
          this.$post("/index/random/download", parameter).then((res) => {
            // location.reload()
          });
        } else {
          // this.$message.error("不能下载随机数");
          if (this.yuyan == "zh") {
            this.$message({
              message: '不能下载随机数',
              type: 'error',
              offset: '350'
            });
          } else {
            this.$message({
              message: 'Unable to download random numbers.',
              type: 'error',
              offset: '350'
            });
          }

        }
      });
    },
    // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.historyList();
    },
    //查询
    selectList() { },
    //历史下载列表
    historyList() {
      this.yuyan = localStorage.getItem('locale')
      let userToken = this.$cookie.get("userToken");
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
        page: this.currentPage,
        limit: this.pagesize
      };
      if (userToken) {
        this.$post("/index/RandomDownload/list", data).then((res) => {
          if (!res.data) {
            // this.$message.error("暂未数据");
            if (this.yuyan == 'zh') {
              this.$message({
                message: '暂未数据',
                type: 'error',
                offset: '350'
              });
            } else {
              this.$message({
                message: 'No data yet',
                type: 'error',
                offset: '350'
              });
            }

            return;
          }
          this.downloadList = res.data.data;
          this.total = res.data.total;
          res.data.data.forEach(item => {
            this.randomId = item.random_id
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}


.main {
  min-height: 700px;
  width: 70%;
  margin: 0 auto;
  display: flex;
  text-align: left;
}

.main .custom-tab {
  /*padding-top: 20px;*/
  width: 140px;
  /* overflow: hidden; */
}

.main .custom-tab-body {
  width: 100%;
}

.main .custom-tab .custom-tab-title {
  width: 140px;
  font-size: 18px;
  text-align: center;
  padding: 4px 0;
  cursor: pointer;
  margin: 28px 0;
}

.main .custom-tab .custom-tab-title span {
  display: block;
  margin: 0 auto;
  font-size: 16px;
}

.main .custom-tab .custom-tab-title i {
  border-bottom: #ffffff 4px solid;
  width: 70px;
  height: 1px;
  display: inline-block;
}

.main .custom-tab .custom-tab-title .active {
  border-bottom: #0F88EB 4px solid;
}

.main .custom-tab-body .custom-tab-item {
  min-height: 700px;
  text-align: left;
  padding: 32px 0 0 120px;
}

.main .custom-tab-body .custom-tab-body-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.main .custom-tab-body .custom-tab-body-item {
  min-width: 700px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
  color: #8A8E99;
  display: flex;
  justify-content: space-between;
}

.main .custom-tab-body .custom-tab-body-item .content {}

.main .custom-tab-body .custom-tab-body-item .label {
  display: inline-block;
  width: 120px;
  height: 28px;
  line-height: 28px;
  overflow: hidden;
}

.main .custom-tab-body .custom-tab-body-item .value {
  display: inline-block;
  /*width: 500px;*/
  height: 28px;
  line-height: 28px;
  overflow: hidden;
}

.main .custom-tab-body .custom-tab-body-item .button {
  margin-right: 30px;
}

@import "../../public/css/download.css";
</style>
